import React from 'react'
import styles from './BannerImage.module.css'

export default ({ isMenuOpen, img = {} }) => {
    const maxZIndex = isMenuOpen ? ` ${styles.maxZIndex} ` : ''
    return (
        <div className={styles.container + maxZIndex}>
            <div className={styles.bannerContainer + maxZIndex} />
            <div className={styles.imageContainer}>
                <img 
                    id="banner-image"
                    alt={img.alt} 
                    src={img.src}
                    height={img.height}
                    width={img.width}
                    className={styles.bannerImage + maxZIndex}
                />
            </div>
            <div className={styles.redStrip + maxZIndex} />
        </div>
    )
}