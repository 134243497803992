const pressCtaText = "READ MORE"
const boxCTAHash = "join-the-factory"
const boxCTALinkText = "About us"
const carouselHash = "our-brands"
const carouselLinkText = "Our brands"

const config = {
    links: [
      {
        text: 'Home',
        href: '/#top',
      },
      { 
          text: carouselLinkText,
          href: `/#${carouselHash}`,
      },
      { 
          text: boxCTALinkText,
          href: `/#${boxCTAHash}`,
      },
      {
          text: 'Grow with us',
          href: '/grow-with-us'
      },
      {
          text: 'Career',
          href: 'https://apply.workable.com/factory14/'
      },
      {
          text: 'Press',
          href: '/press'
      }
    ],
    home: {
        bullets: [
            {
              title: 'We resonate',
              description: 'Every conversation amplifies our goals'
            },
            {
              title: 'We calibrate',
              description: 'Commitment to quality nurtures excellence'
            },
            {
              title: 'We excel',
              description: "The extra mile is our walk to victory"
            },
            {
              title: 'We venture',
              description: "Perseverance moves us further forward"
            }
        ],
        carousel: {
            title: "Our brands speak for us",
            hash: carouselHash,
            linkText: carouselLinkText,
            carouselCards: [
                {
                  brandName: 'ProBikeTools',
                  src: '/images/carousel/PBT.webp',
                },
                {
                  brandName: 'Outlery',
                  src: '/images/carousel/outlery1.webp',
                },
                {
                  brandName: 'Silvon',
                  src: '/images/carousel/silvon.webp',
                },
                {
                  brandName: 'TribeWOD',
                  src: '/images/carousel/tribewod.webp',
                  // logo: '/images/carousel/logos/tribe_logo.webp'
                },
                {
                  brandName: 'Hinoki',
                  src: '/images/carousel/hinoki2.webp',
                  // logo: 'data:image/svg+xml;base64,PHN2ZyBjbGFzcz0ic2l0ZS1oZWFkZXJfX2xvZ28taW1hZ2UiIGRhdGEtbmFtZT0iTGF5ZXIgMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgMTMyIDU0Ljk5Ij4KICA8dGl0bGU+SGlub2tpPC90aXRsZT4KICA8cG9seWdvbiBjbGFzcz0ibG9nby1maWxsIiBwb2ludHM9IjcuMjYgMjkuOTkgNy4yNiAwIDAgNy4yNiAwIDcuMjYgMCA0Ny43MiA3LjI2IDU0Ljk5IDcuMjYgMzIuMDUgMTAuNjEgMjguNzEgMTYuODQgMzQuOTMgMTYuODQgNDcuNzIgMjQuMSA1NC45OSAyNC4xIDI3LjY3IDE2Ljg0IDIwLjQgNy4yNiAyOS45OSI+PC9wb2x5Z29uPgogIDxwb2x5Z29uIGNsYXNzPSJsb2dvLWZpbGwiIHBvaW50cz0iMjguOTYgNDcuNzIgMzYuMjMgNTQuOTkgMzYuMjMgMjAuNCAyOC45NiAyNy42NyAyOC45NiA0Ny43MiI+PC9wb2x5Z29uPgogIDxyZWN0IGNsYXNzPSJsb2dvLWZpbGwiIHg9IjI3LjU5IiB5PSIxOC45MyIgd2lkdGg9IjEwLjUyIiBoZWlnaHQ9IjEuNDYiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC00LjI4IDI4Ljk4KSByb3RhdGUoLTQ1KSI+PC9yZWN0PgogIDxwb2x5Z29uIGNsYXNzPSJsb2dvLWZpbGwiIHBvaW50cz0iMTIzLjg4IDQ3LjcyIDEzMS4xNSA1NC45OSAxMzEuMTUgMjAuNCAxMjMuODggMjcuNjcgMTIzLjg4IDQ3LjcyIj48L3BvbHlnb24+CiAgPHJlY3QgY2xhc3M9ImxvZ28tZmlsbCIgeD0iMTIyLjEiIHk9IjE5LjEiIHdpZHRoPSIxMC45OSIgaGVpZ2h0PSIxLjQ2IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyMy4zNSA5Ni4wMykgcm90YXRlKC00NSkiPjwvcmVjdD4KICA8cG9seWdvbiBjbGFzcz0ibG9nby1maWxsIiBwb2ludHM9IjQ4LjYxIDI5LjY2IDQ4LjYxIDIwLjQgNDEuMzQgMjcuNjcgNDEuMzQgMjcuNjcgNDEuMzQgNDcuNzIgNDguNjEgNTQuOTkgNDguNjEgMzEuNzIgNTEuNjIgMjguNzEgNTcuODUgMzQuOTMgNTcuODUgNDcuNzIgNjUuMTEgNTQuOTkgNjUuMTEgMjcuNjcgNTcuODUgMjAuNCA0OC42MSAyOS42NiI+PC9wb2x5Z29uPgogIDxwb2x5Z29uIGNsYXNzPSJsb2dvLWZpbGwiIHBvaW50cz0iMTExLjQ0IDE5LjQzIDEwNC4xNyAyNi43IDExMC43OCAzMy4zIDEwNC4xNyAzOS45MSAxMDQuMTcgMjYuNyAxMDQuMTcgMCA5Ni45MSA3LjI2IDk2LjkxIDcuMjYgOTYuOTEgNDcuNzIgMTA0LjE3IDU0Ljk5IDEwNC4xNyA0MS45OCAxMTEuNDQgMzQuNzEgMTExLjQ0IDQ3LjcyIDExOC43IDU0Ljk5IDExOC43IDM0LjA4IDExMi4wNyAzNC4wOCAxMTkuMSAyNy4wNSAxMTEuNDQgMTkuNDMiPjwvcG9seWdvbj4KICA8cGF0aCBjbGFzcz0ibG9nby1maWxsIiBkPSJNNzAsMzQuMzVWNDcuNzJMNzcuMzEsNTUsOTEuODUsNDAuNDZWMjdMODQuNTgsMTkuN1pNODQuNTgsNDUuNjRsLTEsMS02LjIzLTYuMjNWMjkuMTFMNzguMzgsMjhsNi4yLDYuMloiPjwvcGF0aD4KPC9zdmc+'
                },
                {
                  brandName: 'Outlery',
                  src: '/images/carousel/outlery2.webp',
                },
                {
                  brandName: 'ProBikeTools',
                  src: '/images/carousel/PBT2.webp',
                },
                {
                  brandName: 'Silvon',
                  src: '/images/carousel/silvon.webp',
                },
                {
                  brandName: 'TribeWOD',
                  src: '/images/carousel/tribewod2.webp',
                  // logo: '/images/carousel/logos/tribe_logo.webp'
                },
                {
                  brandName: 'Outlery',
                  src: '/images/carousel/outlery3.webp',
                },
                {
                  brandName: 'ProBikeTools',
                  src: '/images/carousel/PBT3.webp',
                },
                {
                  brandName: 'Hinoki',
                  src: '/images/carousel/hinoki1.webp',
                  // logo: 'data:image/svg+xml;base64,PHN2ZyBjbGFzcz0ic2l0ZS1oZWFkZXJfX2xvZ28taW1hZ2UiIGRhdGEtbmFtZT0iTGF5ZXIgMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgMTMyIDU0Ljk5Ij4KICA8dGl0bGU+SGlub2tpPC90aXRsZT4KICA8cG9seWdvbiBjbGFzcz0ibG9nby1maWxsIiBwb2ludHM9IjcuMjYgMjkuOTkgNy4yNiAwIDAgNy4yNiAwIDcuMjYgMCA0Ny43MiA3LjI2IDU0Ljk5IDcuMjYgMzIuMDUgMTAuNjEgMjguNzEgMTYuODQgMzQuOTMgMTYuODQgNDcuNzIgMjQuMSA1NC45OSAyNC4xIDI3LjY3IDE2Ljg0IDIwLjQgNy4yNiAyOS45OSI+PC9wb2x5Z29uPgogIDxwb2x5Z29uIGNsYXNzPSJsb2dvLWZpbGwiIHBvaW50cz0iMjguOTYgNDcuNzIgMzYuMjMgNTQuOTkgMzYuMjMgMjAuNCAyOC45NiAyNy42NyAyOC45NiA0Ny43MiI+PC9wb2x5Z29uPgogIDxyZWN0IGNsYXNzPSJsb2dvLWZpbGwiIHg9IjI3LjU5IiB5PSIxOC45MyIgd2lkdGg9IjEwLjUyIiBoZWlnaHQ9IjEuNDYiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC00LjI4IDI4Ljk4KSByb3RhdGUoLTQ1KSI+PC9yZWN0PgogIDxwb2x5Z29uIGNsYXNzPSJsb2dvLWZpbGwiIHBvaW50cz0iMTIzLjg4IDQ3LjcyIDEzMS4xNSA1NC45OSAxMzEuMTUgMjAuNCAxMjMuODggMjcuNjcgMTIzLjg4IDQ3LjcyIj48L3BvbHlnb24+CiAgPHJlY3QgY2xhc3M9ImxvZ28tZmlsbCIgeD0iMTIyLjEiIHk9IjE5LjEiIHdpZHRoPSIxMC45OSIgaGVpZ2h0PSIxLjQ2IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyMy4zNSA5Ni4wMykgcm90YXRlKC00NSkiPjwvcmVjdD4KICA8cG9seWdvbiBjbGFzcz0ibG9nby1maWxsIiBwb2ludHM9IjQ4LjYxIDI5LjY2IDQ4LjYxIDIwLjQgNDEuMzQgMjcuNjcgNDEuMzQgMjcuNjcgNDEuMzQgNDcuNzIgNDguNjEgNTQuOTkgNDguNjEgMzEuNzIgNTEuNjIgMjguNzEgNTcuODUgMzQuOTMgNTcuODUgNDcuNzIgNjUuMTEgNTQuOTkgNjUuMTEgMjcuNjcgNTcuODUgMjAuNCA0OC42MSAyOS42NiI+PC9wb2x5Z29uPgogIDxwb2x5Z29uIGNsYXNzPSJsb2dvLWZpbGwiIHBvaW50cz0iMTExLjQ0IDE5LjQzIDEwNC4xNyAyNi43IDExMC43OCAzMy4zIDEwNC4xNyAzOS45MSAxMDQuMTcgMjYuNyAxMDQuMTcgMCA5Ni45MSA3LjI2IDk2LjkxIDcuMjYgOTYuOTEgNDcuNzIgMTA0LjE3IDU0Ljk5IDEwNC4xNyA0MS45OCAxMTEuNDQgMzQuNzEgMTExLjQ0IDQ3LjcyIDExOC43IDU0Ljk5IDExOC43IDM0LjA4IDExMi4wNyAzNC4wOCAxMTkuMSAyNy4wNSAxMTEuNDQgMTkuNDMiPjwvcG9seWdvbj4KICA8cGF0aCBjbGFzcz0ibG9nby1maWxsIiBkPSJNNzAsMzQuMzVWNDcuNzJMNzcuMzEsNTUsOTEuODUsNDAuNDZWMjdMODQuNTgsMTkuN1pNODQuNTgsNDUuNjRsLTEsMS02LjIzLTYuMjNWMjkuMTFMNzguMzgsMjhsNi4yLDYuMloiPjwvcGF0aD4KPC9zdmc+'
                },
            ], 
        },
        boxCTA: {
            title: "Join the factory",
            hash: boxCTAHash,
            linkText: boxCTALinkText,
            description: "We partner with brands who are ready to take the next great step in their journey. You can trust us with seriously smooth business acquisition, operational knowledge to outsmart the competition and a growth plan to help your brand reach new heights."
        },
        text: {
            valuesTitle: "A factory of smart investors, smooth operators and open people.",
            valuesSubTitle: "We excel beyond past achievements, innovate on existing logic and discover the next best thing for great brands.",
            investorsTitle: "Backed by some of the most experienced consumer-tech investors in the world.",
        },
        bannerImage: {
          src: "/images/banner-home3.webp",
          alt: "Banner image of a red ceiling ressambling a factory"
        }
    },
    press: {
        title: "Press",
        description: "factory14 was founded to bring brilliant brands to the next level and we take the same approach when building our team. We thrive on differences and believe it is critical to our success as a global company.",
        email: "press@factory14.com",
        pressPreviews: [
            {
              img: {
                src: '/images/press/kk_tw.webp',
                alt: 'Image of products from KettlebellKings and TribeWOD',
              },
              title: "factory14 expands further into fitness with the acquisitions of Kettlebell Kings and Tribe WOD",
              description: "factory14 is excited to welcome Kettlebell Kings - the leading kettlebell brand in the US - and Tribe WOD - a design-focused disruptive entrant into the crossfit community with a vibrant and innovative product lineup.",
              cta: {
                  href: 'https://drive.google.com/file/d/1inu1yhNu4fLVK8LfkNtI9aOtRlDh4JDI/view',
                  text: pressCtaText
              }
            },
            { 
                img: {
                    src: '/images/press/founders.webp',
                    alt: 'Picture of the three founders of Factory14. From left to right, Marcos Gundelach, Gianluca Cocco and Guilherme Steinbruch',
                },
                title: "factory14 raises $200M Seed funding to acquire and scale the most recognizable consumer brands of tomorrow.",
                description: "With one of the largest ever Seed rounds in Europe, factory14 sets out to build the next  generation of champion brands like Anker Innovations...",
                cta: {
                    href: 'https://docs.google.com/document/d/1X-vl8XH8jzTqShURh1xpiW_HBkadTZu4X0cEIMonkHk/edit',
                    text: pressCtaText
                }
            },
            { 
                img: {
                    src: "/images/press/PBT.webp",
                    alt: "Logo of Pro Bike Tools",
                },
                title: "PRO BIKE TOOL joins factory14 for exponential growth opportunity",
                description: "PRO BIKE TOOL is in top gear this year: and factory14 is joining the ride...",
                cta: {
                    href: "https://drive.google.com/file/d/1rpRu6F80J6lNpewSo1jhiDKr7GKeFCQL/view",
                    text: pressCtaText
                }
            },
            { 
                img: {
                    src: "/images/press/techcrunch_img.webp",
                    alt: "Picture from Techcrunch, three cyclists at the side of the road",
                },
                title: "factory14 raises $200M to jump into the Amazon marketplace roll-up race",
                description: "“Some are looking for volume, and are willing to buy up many companies as cheaply as possible. But we took the decision to focus only on high-quality assets,...”",
                cta: {
                    href: "https://techcrunch.com/2021/05/20/factory14-raises-200m-to-jump-into-the-amazon-marketplace-roll-up-race/",
                    text: pressCtaText
                }
            }
        ],
        pressPreviewsLinkText: 'READ MORE',
        bannerImage: {
          src: "/images/banner-press3.webp",
          alt: "Banner image of the press page"
        },
        customHero: {
          title: "Press",
          description: "factory14 was founded to bring brilliant brands to the next level and we take the same approach when building our team. We thrive on differences and believe it is critical to our success as a global company."
        }
    },
    growWithUs: {
      title: "We grow, finance and acquire the world's best digital brands",
      bannerImage: {
        src: "/images/banner-grow3.webp",
        alt: "Banner image of red stairs"
      },
      placeholders: {
        name: "Name",
        email: "Email",
        revenue: "Last 12 months revenue",
        brandName: "Brand name",
        website: "Website",
        additionalInfo: "Tell us your story"
      },
      customHero: {
        description: "factory14 was founded to bring brilliant brands to the next level and we take the same approach when building our team. We thrive on differences and believe it is critical to our success as a global company."
      },
      submitButtonText: 'SUBMIT',
      successMessage: "Thanks for getting in contact. We'll get back to you asap",
      formFailedError: "Oops, something went wrong, please submit again",
      formPostURL: 'https://api-test.factory14.com/v1/lead'
    },
    privacy: {
      bannerImage: {
        src: "/images/banner-press3.webp",
        alt: "Banner image of the press page"
      },
      customHero: {
        title: "PRIVACY POLICY",
        description: "Factory 14 S.à r.l., Privacy Policy"
      }
    },
    investors: [
        {
          src: '/images/investors/dmg.webp',
          alt: 'DMG Ventures',
          width: 256,
          height: 78,
        },
        {
          src: '/images/investors/dncapital.webp',
          alt: 'DN Capital',
          width: 258,
          height: 97
        },
        {
          src: '/images/investors/vpc_logo.webp',
          alt: 'Victory Park Capital',
          width: 280,
          height: 76,
        },
        {
          src: '/images/investors/venturefriends.webp',
          alt: 'Venture Friends',
          width: 256,
          height: 32,
        },
        {
          src: '/images/investors/gsquaredlogo.webp',
          alt: 'G Squared',
          width: 256,
          height: 32,
        },
    ],
    skeleton: {
        text: {
            defaultHeroDescription: "If you own a show-stopping product with a high-growth niche, consider us inspired.",
            footerDescription: 'factory14 is paving the way for new ideas, new avenues and new approaches, housing variety of industries, with a shared ideology. Our experience in acquiring and operating digital business, paired with an eye for brilliant products in high-growth niches, is our recipe for scaling success.'
        }
    },
    404: {
      bannerImage: {
        src: "/images/banner-grow-with-us.webp",
        alt: "Banner image of a road"
      },
      customHero: {
        title: "Page not found",
        description: "We couldn't find the page you're trying to access."
      },
    }
}

export default config;