import React from 'react'
import styles from './FooterBannerImage.module.css'

export default () => (
    <div className={styles.container}>
        <div className={styles.redStrip} />
        <div className={styles.bannerContainer} />
            <img 
                alt="banner-factory-ceiling" 
                src="/images/banner-footer3.webp" 
                loading="lazy"
                className={styles.bannerImage}
            />
    </div>
)