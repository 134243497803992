/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react'
import styles from './Header.module.css'
import Factory14Logo from '../../images/logo.svg'

export default ({ isScrolling, onBurguerClick, isMenuOpen, disableLogo }) => {
    const handleClick = (e) => {
        e.preventDefault();
        onBurguerClick()
    }


    let headerStyle = `${styles.header} ${(isScrolling || isMenuOpen) ? styles.headerOnTop : ''}`
    
    if (isMenuOpen) {
        headerStyle += ` ${styles.headerAlternative}`
    }
    return (
        <header className={headerStyle}>
            {
                isMenuOpen 
                    ?
                <button onClick={handleClick} aria-label="Close menu">
                    <svg className={styles.closeButton} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.5423 0.572406L0.571777 17.543L2.4574 19.4286L19.428 2.45803L17.5423 0.572406Z" fill="white"/>
                        <path d="M19.4281 16.9706L2.45752 0L0.571887 1.88563L17.5424 18.8562L19.4281 16.9706Z" fill="white"/>
                    </svg>
                    <h3 className={styles.closeMenuButtonText}>close</h3>
                </button>
                    :
                <>
                    <svg 
                        viewBox="0 0 1024 1024" 
                        className={`${styles.menu1} ${isScrolling ? styles.menu1Scrolling : ''}`}
                        onClick={handleClick}>
                        <path d="M810.667 725.333h-597.333c-47.061 0-85.333 38.272-85.333 85.333s38.272 85.333 85.333 85.333h597.333c47.061 0 85.333-38.272 85.333-85.333s-38.272-85.333-85.333-85.333z"></path>
                        <path d="M810.667 426.667h-597.333c-47.061 0-85.333 38.272-85.333 85.333s38.272 85.333 85.333 85.333h597.333c47.061 0 85.333-38.272 85.333-85.333s-38.272-85.333-85.333-85.333z"></path>
                        <path d="M810.667 128h-597.333c-47.061 0-85.333 38.272-85.333 85.333s38.272 85.333 85.333 85.333h597.333c47.061 0 85.333-38.272 85.333-85.333s-38.272-85.333-85.333-85.333z"></path>
                    </svg>
                    {
                        isScrolling || !disableLogo ? 
                            <div className={styles.logoContainer + ` ${isScrolling ? styles.isScrolling : ''}`}>
                                <a href="/#top"><Factory14Logo /></a>
                            </div>
                            : null
                    }
                </>
            }

        </header>
    )
}