import React from 'react'
import styles from './Footer.module.css'
import projectStyles from '../../style.module.css'
import F14Logo from '../common/Logo'
import Message from '../../images/message.svg'
import Location from '../../images/location.svg'

export default ({ 
    companyLinks = [],
    mailto = 'acquisitions@factory14.com',
    address = 'Factory 14 S.à r.l. | RCS No B252011 | 121, Avenue de la Faïencerie L - 1511 Luxembourg',
    description,
}) => (
    <div className={styles.footerContainer}>
        <footer className={styles.footer}>
            <div className={`${styles.leftColumn} ${styles.brandingColumn}`}>
                <F14Logo />
                <p className={styles.brandingText}>
                    {description}
                </p>
            </div>
            <div className={`${styles.rightColumn} ${styles.rowOrderedColumn}`}>
                <div className={`${styles.leftColumn} ${styles.companyContainer}`}>
                    <h3 className={styles.footerHeader3}>Company</h3>
                    {
                        companyLinks.map(link => (
                            <a 
                                key={link.href}
                                href={link.href}
                                className={styles.companyLink}> 
                                {link.text} 
                            </a>
                        ))
                    }
                </div>
                <div className={styles.rightColumn}>
                    <h3 className={styles.footerHeader3}>Reach us</h3>
                    <div className={styles.iconWithTextContainer}>
                        <Message />
                        <a href={`mailto:${mailto}`}>{mailto}</a>
                    </div>
                    <div className={styles.iconWithTextContainer}>
                        <Location />
                        <p className={styles.addressText}>
                            {address}
                        </p>
                    </div>
                </div>
            </div>
        </footer>
        <div className={styles.footerLegalsContainer}>
            <p className={` ${projectStyles.body2}`}>
            © factory14® {(new Date()).getFullYear()} | All Rights Reserved | <a href="/privacy-and-policy/#">Privacy & Policy</a>
            </p>
        </div>
    </div>
)