import React from 'react'
import styles from './BrandHero.module.css'
import projectStyles from '../../style.module.css'
import Factory14Logo from '../../images/logo.svg'

export default ({customHero = {}, showLogo}) => (
    <div className={styles.brandContainer}>
        {
            showLogo ?
            <Factory14Logo alt="factory14" className={styles.headlineLogo} />
            :
            null
        }
        
        <h1 className={` ${projectStyles.headline1} ${styles.headline} `}>
            {
                customHero.title ? 
                <span>{customHero.title}</span> 
                :
                <>
                <span>We finance,</span><br />
                <span>acquire and grow</span><br />
                <span>the world’s best</span><br />
                <span>digital brands.</span>
                </>
            }

        </h1>
        <p className={styles.brandHeroText}>
            {customHero.description}
        </p>
    </div>
)