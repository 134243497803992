import React from 'react'

import Helmet from 'react-helmet'

import styles from './Skeleton.module.css'
import BrandHero from '../BrandHero'
import Header from '../Header'
import Footer from '../Footer'
import BannerImage from '../BannerImage'
import FooterBannerImage from '../FooterBannerImage'
import config from '../../config'

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scrolling: false,
      isMenuOpen: false,
    }

    this.toggleMenu = this.toggleMenu.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
  }


  toggleMenu() {
    this.setState({
      isMenuOpen: !this.state.isMenuOpen
    })
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll(event) {
    if(window.scrollY > 150) {
      this.setState({ scrolling: true })
    } else {
      this.setState({ scrolling: false })
    }
  }

  render() {

    const {
      links,
      skeleton: {
          text: {
              defaultHeroDescription,
              footerDescription
          }
      }
    } = config

    let companyLinks = links.map(link => {
      const homeWithAnchorRegex = /\/#/
      if(homeWithAnchorRegex.test(link.href)) {
        return { ...link, onClick: this.toggleMenu }
      }
      return link
    })

    const customHero = {
      description: defaultHeroDescription,
      ...this.props.customHero,
    }

    let containerStyle = styles.container;
    if (this.state.isMenuOpen) containerStyle += ` ${styles.containerHidden}`
    return (
      <React.Fragment>
        <Header 
          isScrolling={this.state.scrolling} 
          isMenuOpen={this.state.isMenuOpen} 
          onBurguerClick={this.toggleMenu}
          disableLogo={this.props.logoInHero}
        />
        <div className={this.state.isMenuOpen ? styles.openMenu : styles.closedMenu}>
          <div className={styles.menuLinksContainer}>
            {
              companyLinks.map(link => (
                <a href={link.href} className={styles.menuLink} onClick={link.onClick} key={link.href}>
                  <h2>{link.text}</h2>
                </a>
              ))
            }
          </div>
        </div> 
        <div className={containerStyle}>
          <Helmet>
            <title>factory14</title>
            <meta property="og:title" content="factory14" />
          </Helmet>
          <div className={styles.main}>
            <BannerImage 
              img={this.props.bannerImage}
              isMenuOpen={this.state.isMenuOpen} />
            <div className={styles.contentContainer}>
              <BrandHero 
                customHero={customHero}
                showLogo={this.props.logoInHero} />


                {this.props.children}


              </div>
            </div>
          

          {
            this.props.disableFooterImage ? 
            null 
              :
            <div>
              <FooterBannerImage />
            </div>
          }
          
          <br />
          <Footer description={footerDescription} companyLinks={companyLinks} />
        </div>
      </React.Fragment>
    )
  }
}

export default Home
